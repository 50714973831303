export default {
  path: "/powerups/intelligence",
  name: "powerups-intelligence",
  components: {
    mobile: () => import("Layouts/menus/mobile/Default"),
    sidebar: () => import("Layouts/menus/sidebar/Default"),
    header: () => import("Components/headers/Default.vue"),
    contentHeader: () => import("Components/contentHeaders/Default.vue"),
    footer: () => import("Components/footers/Default.vue"),
    main: () => import("Views/powerups/Intelligence.vue"),
  },
  meta: {
    auth: true,
    editable: [],
    readable: ["admin", "super", "system", "support","conf"],
    guarded: false,
  },
};
