import { defineStore } from "pinia";
import API from "Api/API";
import AppConfig from "Constants/AppConfig";
import GeoData from "Data/GeoData";

import Profile from "Models/users/Profile";

export const useProfileStore = defineStore("profile", {
  state: () => ({
    user: false,
    adminScreen: false,
    identities: [],
  }),
  getters: {
    currentUser() {
      return Object.assign(new Profile(), this.user);
    },
    isAdmin() {
      return this.user && this.user.allow?.admin === true;
    },
    isBilling() {
      return this.user && this.user.allow?.billing === true;
    },
    isSupport() {
      return this.user && this.user.allow?.support === true;
    },
    isConf() {
      return this.user && this.user.allow?.conf === true;
    },
    isUsers() {
      return this.user && this.user.allow?.users === true;
    },
    isAdminSupport() {
      return (
        this.user &&
        (this.user.allow?.admin === true || this.user.allow?.support === true)
      );
    },
    isReseller() {
      return this.user && Boolean(this.user.allow?.super);
    },
    isSystem() {
      return this.user && Boolean(this.user.allow?.system);
    },
  },
  actions: {
    async getIdentities() {
      try {
        const response = await API.Admin.clientList();

        this.identities = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
        );
      } catch (err) {}
    },
    async getProfile() {
      try {
        const response = await API.Users.getMyProfile();

        this.user = response;
        this.user.locale =
          GeoData.countries[this.user.country].lang.split(",")[0];

        if (AppConfig.debug) {
          console.log("Profile: ", this.user);
        }

        if (
          this.user &&
          (this.user.allow.system === true)
        ) {
          this.adminScreen = true
          this.getIdentities();
        }
      } catch (err) {}
    },
  },
  persist: true,
});
